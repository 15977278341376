<template>
  <div>
    <v-container fluid>
      <div class="main">

        <v-card class="rounded-lg shadow">

          <v-card-title>
            <div>
              <v-text-field
                  v-model="keyword"
                  append-icon="mdi-magnify"
                  clearable
                  dense
                  filled
                  hide-details
                  placeholder="Rechercher..."
                  rounded
                  single-line
              />
            </div>
            <v-spacer></v-spacer>

            <div class="d-flex">

              <v-select hide-details
                        v-model="firstYear"
                        outlined
                        dense
                        :style="{width: '100px'}"
                        :items="[2020,2021,2023,2024,2025,2026,2027,2028,2029,2030]"
                        label="1ere année"></v-select>
              &nbsp;
              <v-select hide-details
                        v-model="secondYear"
                        outlined
                        :style="{width: '100px'}"
                        dense
                        :items="[2020,2021,2023,2024,2025,2026,2027,2028,2029,2030]"
                        label="2ème année"></v-select>

            </div>

            &nbsp;

            <v-btn text color="gifty" @click="exportExcel">
              <v-icon left>mdi-download</v-icon>
              Exporter
            </v-btn>
            <v-btn icon @click="getAnalysesPartners">
              <v-icon>mdi-reload</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text class="pa-0">

            <v-skeleton-loader v-if="isLoading" type="table"/>

            <div v-if="partners.length && !isLoading">

              <v-divider/>

              <v-data-table :search="keyword"
                            :headers="headers"
                            :items="partners"
              >

                <template v-slot:item.name="{ item }">
                  <span class="font-weight-medium">
                    {{ item.name }}
                  </span>
                </template>

                <template v-slot:item.ca_1="{ item }">
                  <span class="font-weight-medium text-no-wrap">
                    <span class="primary--text">
                      {{ CurrencyFormatting(item.ca_1) }}
                    </span>
                       <span class="f-13">
                     /  Moy. : {{ CurrencyFormatting(item.moy_ca_1) }}
                    </span>
                  </span>
                </template>

                <template v-slot:item.ca_2="{ item }">
                  <span class="font-weight-medium text-no-wrap">
                    <span class="gifty--text">
                      {{ CurrencyFormatting(item.ca_2) }}
                    </span>
                       <span class="f-13">
                     /  Moy. : {{ CurrencyFormatting(item.moy_ca_2) }}
                    </span>
                  </span>
                </template>


                <template v-slot:item.total_ca="{ item }">
                  <span class="font-weight-medium text-no-wrap">
                      {{ CurrencyFormatting(item.total_ca) }}
                  </span>
                </template>

              </v-data-table>

            </div>

            <div v-if="!partners.length && !isLoading">
              <NoResults @reload="getAnalysesPartners"/>
            </div>

          </v-card-text>
        </v-card>

      </div>
    </v-container>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";
import NoResults from "@/components/NoResults.vue";
import moment from "moment";

export default {
  components: {NoResults},
  data() {
    return {
      keyword: null,
      isLoading: false,
      partners: [],
      headers: [
        {text: 'Partenaire', value: 'name'},
        {text: '', value: 'ca_1'},
        {text: '', value: 'ca_2'},
        {text: 'Total CA', value: 'total_ca'},
        {text: 'Date du dernier achat', value: 'latest_purchase_date'},
      ],
      firstYear: moment().subtract(1, 'year').year(),
      secondYear: moment().year(),
    };
  },
  methods: {
    getAnalysesPartners() {
      this.isLoading = true;
      HTTP.get("v1/partner-analyses", {
        params: {
          firstYear: this.firstYear,
          secondYear: this.secondYear
        }
      })
          .then((res) => {
            this.partners = res.data.data
            this.isLoading = false

            let first_year = res.data.first_year
            let second_year = res.data.second_year

            /**
             * Name headers by year
             * @type {string}
             */
            this.headers[1]['text'] = 'CA ' + first_year

            this.headers[2]['text'] = 'CA ' + second_year

          })
          .catch((err) => {
            this.isLoading = false;
            console.log(err);
          });
    },
    exportExcel() {
      let baseUrl = process.env.VUE_APP_FILE_URL;
      let token = this.$store.state.user.access_token;
      let userId = this.$store.state.user.user.id;
      let url = baseUrl + "/api/v1/partner-analyses/export?token=" + token + "&user_id=" + userId + "&firstYear=" + this.firstYear + "&secondYear=" + this.secondYear;

      window.open(url, '_blanc')
    }
  },
  created() {
    this.getAnalysesPartners()
  },
  watch: {
    firstYear() {
      this.getAnalysesPartners()
    },
    secondYear() {
      this.getAnalysesPartners()
    },
  }
};
</script>